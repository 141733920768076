import React from "react";
import Seo from "~/components/Seo";
import "~/features/marketing/404.css";

function NotFoundPage() {
  return (
    <div className="container page-not-found">
      <h1>Page Not Found</h1>
      <p>
        We couldn&apos;t find the page you were looking for. This is either
        because:
      </p>
      <ul>
        <li>
          There is an error in the URL entered into your web browser. Please
          check the URL and try again.
        </li>
        <li>The page you are looking for has been moved or deleted.</li>
      </ul>
      <p>
        You can return to our homepage by <a href="/">clicking here</a>.
      </p>
    </div>
  );
}

export function Head() {
  return <Seo title="Page Not Found" />;
}

export default NotFoundPage;
